import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Logo from "../../components/logo"
import BuyButton from "../../components/buy"
import Footer from "../../components/footer"
import Bunny from "../../components/images/bunny"
import Bingo from "../../components/images/bingo"
import Vini from "../../components/images/vini"

const Language = "en-EN"

const IndexPage = () => (
  <Layout>
    <SEO title="Easter chocolates | Vegan chocolates" lang={Language} />
    <Logo className="mx-auto h-auto w-56 pt-8 lg:absolute lg:z-10 lg:left-0 lg:ml-12" route="https://candide.berlin/home" />
    <div className="px-6 text-center md:flex md:items-center">
      <div className="md:w-1/2">
        <Bunny className=""/>
      </div>
      <div className="md:w-1/2 md:text-left md:pr-8">
        <h2 className="mt-8 text-4xl font-bold leading-tight text-gray-800 md:text-5xl md:leading-none lg:text-6xl">Don't miss this chocolate egghunt</h2>
        <p className="mt-2 text-xl font-medium leading-tight text-canblue md:mt-3 lg:mt-4 lg:text-2xl lg:leading-none lg:tracking-wide">Waterganache of Beni Wild and Carrot.</p>
        <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">
          Scotty is a blue bunny that loves carrots. 
          We're making an Easter chocolate together: 
          <span className="font-bold text-gray-800"> It's vegan with carrots.</span>
        </p>
        <p className="mt-4 text-lg text-gray-800 md:font-thin">Super delicious and limited edition. <br />They're made to order, so reserve your boxes now.</p>
        <div className="mt-4 md:flex md:items-end md:justify-start">
          <BuyButton className="pt-2 md:mx-0" shopifyId="4420557471878" title="PICKUP" background="#327BB3" color="#ffffff" border="1px solid #327BB3" showVariantOptions={true} />
          <BuyButton className="mt-4 border-t md:mx-0 md:border-0 md:ml-4" shopifyId="4417770258566" title="SHIPPING" background="#718096" color="#ffffff" border="1px solid #718096" />
        </div>
        <p className="mt-4 text-sm text-gray-600 italic md:font-thin">Order by March 31st. <br />Get them at one of our pickups or have them delivered to your home.</p>
      </div>
    </div>
    <div className="mt-12 bg-canblue h-40 flex items-center justify-center text-center md:mt-0 lg:justify-end lg:text-right">
      <p className="text-white tracking-wide italic px-8 md:px-20 md:font-thin">"Chocolates by Scotty the blue bunny, oh my God, I have to try them." <br />Peter Tinaglia</p>
    </div>
    <div className="px-6 border-t-8 border-canred">
      <div className="text-center max-w-xl mx-auto">
        <h3 className="mt-12 text-3xl font-bold text-gray-800 md:text-4xl lg:mt-20">Pickup Dates</h3>
        <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">We can send you the chocolates or you pick them up.</p>
        <p className="text-lg text-gray-800 md:font-thin lg:font-hairline">Please chose from one of these options:</p>
      </div>
    </div>
    <div className="mt-8 max-w-5xl mx-auto text-center md:flex md:items-center md:shadow-xl md:border md:border-gray-200 lg:rounded-lg md:overflow-hidden">
      <div className="md:w-1/3">
        <Bingo className="md:h-64" />
      </div>
      <div className="md:w-1/3 md:text-left md:pl-10 md:border-r md:border-gray-200">
        <h4 className="mt-8 text-2xl font-bold leading-tight text-gray-800 md:mt-0">Bunny Bingo</h4>
        <h5 className="text-lg text-gray-800 md:font-thin">Zum Starken August</h5>
        <ul className="mt-4 text-lg text-gray-800 md:font-thin">
          <li>Schönhauser Allee 56</li>
          <li>10437 Berlin (City Ost)</li>
        </ul>
        <p className="mt-4 text-lg text-gray-800 md:font-thin">20:00 to 23:00</p>
        
      </div>
      <div className="md:w-1/3">
        <div className="font-bold text-gray-800 uppercase tracking-wide">
          <span className="leading-none">Monday</span>
          <span className="pl-2 md:block md:pl-0 md:text-4xl md:leading-none ">6. April</span>
        </div>
        <BuyButton shopifyId="4420557471878" variantId="31445164621958" title="PICKUP" />

      </div>
    </div>
    <div className="mt-12 max-w-5xl mx-auto text-center md:flex md:items-center md:shadow-xl md:border md:border-gray-200 lg:rounded-lg md:overflow-hidden">
      <div className="md:w-1/3">
        <Vini className="md:h-64" />
      </div>
      <div className="md:w-1/3 md:text-left md:pl-10 md:border-r md:border-gray-200">
        <h4 className="mt-8 text-2xl font-bold leading-tight text-gray-800 md:mt-0">Vini Culture</h4>
        <ul className="mt-4 text-lg text-gray-800 md:font-thin">
          <li>Grolmannstrasse 44-45</li>
          <li>10623 Berlin (City West)</li>
        </ul>
        <p className="mt-4 text-lg text-gray-800 md:font-thin">17:00 to 20:00</p>
        
      </div>
      <div className="md:w-1/3">
        <div className="font-bold text-gray-800 uppercase tracking-wide">
          <span className="leading-none">Tuesday</span>
          <span className="pl-2 md:block md:pl-0 md:text-4xl md:leading-none ">7. April</span>
        </div>
        <BuyButton shopifyId="4420557471878" variantId="31445164654726" title="PICKUP" />
      </div>
    </div>
    <div className="mt-12 px-6 py-12 bg-gray-100 text-center">
      <h3 className="text-3xl font-bold text-gray-800 md:text-4xl">Shipping</h3>
      <p className="mt-4 text-lg text-gray-800 md:font-thin">No, I can't come to pickup.<br />I want to receive the chocolates at home.</p>
      <p className="mt-4 text-lg text-gray-800 md:font-thin">We ship everything on Monday April 6 <br/>Your gifts should arrive on Tuesday or Wednesday.</p>        
      <BuyButton className="mt-2" shopifyId="4417770258566" variantId="all" title="SHIPPING" background="#718096" color="#ffffff" border="1px solid #718096" />
    </div>
    <div className="mt-12 px-6 text-center">
      <h3 className="mt-12 text-3xl font-bold text-gray-800 leading-tight md:text-4xl lg:mt-12">Summary</h3>
      <p className="text-lg font-medium text-canblue md:font-thin lg:font-hairline">Just the facts.</p>
      <p className="mt-6 text-lg text-gray-800 md:font-thin lg:font-hairline">We're making an Easter chocolate.<br />With <a href="https://www.instagram.com/scottybunny" className="text-canblue underline">Scotty the Blue Bunny.</a></p>
      <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">Easter chocolates are only available now <br />And only if pre-ordered.</p>
      <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">They're made of Beni Wild and Carrot. <br />It's a water ganache - so they're VEGAN.</p>
      <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">It's a super limited edition and includes an Easter card. <span className="block font-bold">€29,95 for 18 Pieces</span></p>
      <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">You order them online. <br/>Either we ship them to you. <br />Or you pick them up at one of the pickup times. </p>
      <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">Please let us know, what you prefer. </p>
      <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">There will be a paired wine.<br />You can order it online or get it at ViniCulture.</p>
      <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">Any questions?</p>
    </div>
    <Footer lang={Language} />
  </Layout>
)

export default IndexPage
